// Imports: Dependencies
import { combineReducers } from 'redux';

// Imports: Reducers
import authReducer from './authReducer';
import appReducer from './appReducer';
import familyReducer from './familyReducer';
import schoolReducer from './schoolReducer';
import agentReducer from './agentReducer';
import groupReducer from './groupReducer';

// Redux: Root Reducer
const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  family: familyReducer,
  school: schoolReducer,
  agent: agentReducer,
  group: groupReducer
});

// Exports
export default rootReducer;
