import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  Box,
  Typography,
  Button,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import FullPage from "../../layout/FullPage";
import { routes } from "../../utils/constant";
import { withFirebase, FIREBASE_API_URL } from "../../utils/firebase";
import { addMessage } from "../../redux/actions/appActions";

const useStyles = makeStyles(() => ({
  constWrapper: {
    marginLeft: 55,
    marginRight: 55,
  },
  imageWrapper: {
    marginRight: "20px",
  },
  typo1: {
    fontSize: "16px",
    marginTop: 0,
  },
  typo2: {
    fontSize: "32px",
    marginTop: 24,
  },
  typo3: {
    fontSize: "16px",
    marginTop: 24,
  },
  typo4: {
    fontSize: "14px",
    marginTop: 144,
    marginBottom: 17,
  },
}));

function UnsubsribeView({ firebase }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [showButton, setShowButton] = useState(false)
  const query = new URLSearchParams(window.location.search);
  const unsubscribeToken = query.get("token");
  const getParams = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    fetch(
      `${FIREBASE_API_URL}/handleSubscription?token=${unsubscribeToken}`,
      getParams
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result, "result");
          if (result.code === 400) {
            dispatch(addMessage("Error! Token is invalid"));
            setShowButton(false)
          } else {
            setShowButton(true)
            setData(result.data);
          }
        },
        (error) => {
          console.log("error");
        }
      );
  }, []);

  const handleSubmit = () => {
    console.log(data, "data");
    fetch(
      `${FIREBASE_API_URL}/handleSubscription?token=${data?.token}`,
      getParams
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result, "result");
          dispatch(addMessage("Resubscribed has been done. "));
          navigate(routes.reSubscribe, { state: { data } });
        },
        (error) => {
          dispatch(addMessage("Error! Please try again. "));
          console.log("error", error);
        }
      );
  };

  return (
    <FullPage>
      <div className={classes.constWrapper}>
        <Box mt={2}>
          <Typography className={classes.typo1}>{data?.email}</Typography>

          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom={true}
            className={classes.typo2}
          >
            You’re Unsubscribed!
          </Typography>

          <Typography className={classes.typo3}>
            You will no longer receive emails from the {data?.listName} mailing
            list{" "}
          </Typography>

          <Typography className={classes.typo4}>
            Did you unsubscribe by accident?
          </Typography>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            style={{backgroundColor:  showButton=== false ?  '#EBEBE4' : "#8bc517" , color: "#fff" }}
            disabled={showButton=== false ? true : false}
          >
            Re-subscribe me
          </Button>
        </Box>
      </div>
    </FullPage>
  );
}

export default withFirebase(UnsubsribeView);
