import React from "react";
import { Box, Typography, Button, makeStyles, Link } from "@material-ui/core";
import Logo from "../../component/Logo";
import FullPage from "../../layout/FullPage";

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 32,
    // backgroundColor: "#fdfbf9",
    maxWidth: 460,
    margin: "auto",
  },
  logo: {
    marginBottom: 42,
  },
  footerLinks: {
    marginTop: 375,
    "& .MuiLink-root": {
      marginRight: 35,
    },
  },
}));

function MobilePasswordUpdatedView() {
  const classes = useStyles();

  const launchApp = () => {
    window.location = "mywhanau://login";
  };

  return (
    <FullPage>
      <Box className={classes.wrapper}>
        <div className={classes.logo}>{/* <Logo width="100" /> */}</div>
        <Typography variant="h2" color="textPrimary" gutterBottom={true}>
          Password Updated
        </Typography>
        <Typography variant="subtitle1" color="textPrimary" gutterBottom={true}>
          Your password has been successfully updated. You can now sign in to
          the app.
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => launchApp()}
        >
          Launch App
        </Button>
        <Typography className={classes.footerLinks}>
          <Link
            href="mailto:care@mywhanau.com.au"
            color="inherit"
            underline="none"
          >
            Contact
          </Link>
          <Link
            href="https://www.mywhanau.com.au/privacy-policy"
            color="inherit"
            underline="none"
            target="_blank"
          >
            Privacy
          </Link>
          <Link
            href="https://www.mywhanau.com.au/terms-and-conditions"
            color="inherit"
            underline="none"
            target="_blank"
          >
            Terms
          </Link>
        </Typography>
      </Box>
    </FullPage>
  );
}

export default MobilePasswordUpdatedView;
