import { schoolSchema } from '../../utils/schemas';
// Initial State
const initialState = {
  currentSchool: schoolSchema
};

// Reducers (Modifies The State And Returns A New State)
const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    // School Set
    case 'SET_SCHOOL': {
      return {
        ...state,
        currentSchool: { ...state.currentSchool, ...action.school }
      };
    }
    case 'RESET_SCHOOL': {
      return {
        ...state,
        currentSchool: schoolSchema
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default schoolReducer;
