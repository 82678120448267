import CryptoJS from 'crypto-js';
import moment from 'moment';
import { FIREBASE_API_URL } from './firebase';
import { encryptedPasswordSecret, emailTypes } from './constant';
/**
 * Get query string params from url
 *
 * @param {string} name name of the query param.
 * @param {string} url Url from which we have to extract param.
 * @return {string} value of param
 */

export const getParameterByName = (name, url) => {
  if (!url) return '';
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isCodeVerified = () => {
  return localStorage.getItem('isCodeVerified') || null;
};
export const isCodeVerifiedSession = () => {
  return window.sessionStorage.getItem('isCodeVerified') || null;
};

export const encryptSHA256 = (text) => {
  return CryptoJS.enc.Base64.stringify(
    CryptoJS.HmacSHA256(text, encryptedPasswordSecret)
  );
};

export const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export const generateFileHash = (length = 9) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const setSchoolFileName = (file, schoolId, type) => `${schoolId}-${type}.${file.name.split('.').pop()}`;

export const generateMonthOptionsForFilter = () => {
  const now = moment();
  const currentMonth = moment(now)
    .subtract(1, "years").subtract(1, "months")
    .format("MM YYYY");
  const calenderView = [];
  let prevMonth = moment(now);
  while (prevMonth.format("MM YYYY") !== currentMonth) {
    calenderView.push({
      label: prevMonth.format("MMMM YYYY"),
      value: prevMonth.format("YYYY-MM-DD"),
    });
    prevMonth = prevMonth.subtract(1, "months");
  }
  return calenderView;
};

//start generate password 8 characters, one uppercase letter, one lowercase letter and one number.
// Ref : https://stackoverflow.com/questions/20856822/javascript-random-password-with-specific-format

let upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
let lower = 'abcdefghijklmnopqrstuvwxyz'
let digit = '0123456789'
// let spacialChar = '!@#$%^&*(()_'
let all = upper + lower + digit

function rand (max) {
  return Math.floor(Math.random() * max)
}

function random (set) {
  return set[rand(set.length - 1)]
}

function generate (length, set) {
  let result = []
  while (length--) result.push(random(set))
  return result
}

function shuffle (arr) {
  let result = []

  while (arr.length) {
    result = result.concat(arr.splice(rand[arr.length - 1]))
  }

  return result
}

export const  generatePatternPassword = (length)=> {
  let result = [] // we need to ensure we have some characters

  result = result.concat(generate(1, upper)) // 1 upper case
  result = result.concat(generate(1, lower)) // 1 lower case
  result = result.concat(generate(1, digit)) // 1 digit
  // result = result.concat(generate(1, spacialChar)) // 1 spacial char
  result = result.concat(generate(length - 3, all)) // remaining - whatever

  return shuffle(result).join('') // shuffle and make a string
}

//end generate password 8 characters, one uppercase letter, one lowercase letter and one number.

export const formatFreeFamily = (familyData, status = null) => {
  const childs = formatChilds(familyData.childs, status);
  const formatted = {
    childs,
    childsSchools: [],
    country: familyData.country,
    createdDate: familyData.createdDate || moment.utc().format(),
    encryptedPassword: familyData.encryptedPassword || '',
    familySurname: familyData.familySurname || setSurname(familyData.childs),
    freeHost: 'true',
    id: familyData.id || '',
    myWhanauEmail: '',
    parents: formatParents(familyData),
    password: '',
    planStatus: '',
    planType: familyData.planType || 'Free',
    primaryEmail: familyData.primaryEmail,
    schoolIds: familyData.schoolIds || [],
    schools: familyData.schools || [],
    timezone: familyData.timezone,
    userId: familyData.userId || '',
    userName: familyData.userName || '',
    termsChecked: '',
    accountStatus: childs.map((child) => child.status)
  };
  return formatted;
};

const formatParents = (familyData) => {
  return [...familyData.parents];
};

const formatChilds = (childs, status = null) => {
  return childs.map((child) => {
    const formated = {
      createdDate: child.createdDate
        ? child.createdDate
        : moment.utc().format(),
      status: child.status || status,
      statusUpdatedAt:
        (status === null || child.status === status) && child.statusUpdatedAt
          ? child.statusUpdatedAt
          : moment.utc().format(),
      id: child.id || generateFileHash(20),
      firstName: child.firstName,
      group: child.group || '',
      groups: child.groups,
      initials: child.initials || `${child.firstName[0]}${child.lastName[0]}`,
      lastName: child.lastName,
      password: child.password || '',
      school: child.school,
      schoolPortal: child.schoolPortal || '',
      schoolPortalLogin: child.schoolPortalLogin || '',
      schoolPortalPassword: child.schoolPortalPassword || '',
      userName: child.userName || '',
      year: child.year,
      years: child.years
    };
    return formated;
  });
};

const setSurname = (childs) => {
  let surname = childs?.[0]?.lastName || '';
  if (childs.length > 1 && childs[0].lastName !== childs[1].lastName)
    surname = `${surname}-${childs[1].lastName}`;
  return surname;
};

const getEmailParamas = ({ type, customData, sendTo, needEventTracking = false }) => {
  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ type, customData, sendTo, needEventTracking })
  };
  return params;
}

export const emailToApprovedFamily = async (familyData, schoolName = '') => {
  const { primaryEmail } = familyData;
  const { firstName } = familyData.parents[0];
  const params = getEmailParamas({
    type: emailTypes.MEMBER_APPROVED.type,
    customData: { ...emailTypes.MEMBER_APPROVED.customData, firstName, schoolName, userEmail: primaryEmail },
    sendTo: primaryEmail,
    needEventTracking: true
  });
  try {
    const res = await fetch(`${FIREBASE_API_URL}/sendEmail`, params);
    return res;
  } catch (err) {
    console.log('ERROR: ', err);
    return err;
  }
}
