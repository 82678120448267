import { familySchema } from '../../utils/schemas';
// Initial State
const initialState = {
  currentFamily: familySchema
};

// Reducers (Modifies The State And Returns A New State)
const familyReducer = (state = initialState, action) => {
  switch (action.type) {
    // Family Set
    case 'SET_FAMILY': {
      return {
        ...state,
        currentFamily: { ...state.currentFamily, ...action.family }
      };
    }
    case 'RESET_FAMILY': {
      return {
        ...state,
        currentFamily: familySchema
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default familyReducer;
