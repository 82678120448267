import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import _ from 'lodash';
import moment from 'moment';
import { generateFileHash, setSchoolFileName, formatFreeFamily, emailToApprovedFamily, encryptSHA256 } from '../functions';
import { freeFamilyChildStatus } from '../constant';

const { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_DB_URL, REACT_APP_PROJECT, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_MSG_SENDER_ID, REACT_APP_FIREBASE_APP_ID } = process.env;

const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DB_URL,
  projectId: REACT_APP_PROJECT,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage().ref();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // For Agent role after password reset
  setNewPassword = async ({ email, newPassword }) => {
    console.log(email, newPassword)
    try {
      if (!email) { throw new Error('email is required'); }
      if (!newPassword) { throw new Error('newPassword is required'); }
      
      const userSnap = await this.db.collection('users').where('email', '==', email).get();
      if (userSnap.size > 0) {
        const ref = userSnap.docs[0].ref;
        const userData = userSnap.docs[0].data();
        if (userData && ['Agent', 'Manager', 'Admin'].includes(userData.role)) {
          let allEncryptedPSW;
          const encryptedPassword = encryptSHA256(newPassword);
    
          if (userData.prevPasswords) {
            if (userData.prevPasswords.includes(encryptedPassword)) {
              throw new Error('new password must not be one of the old 5 passwords');
            } else {
              allEncryptedPSW = (userData.prevPasswords.length == 5) ?
              // Remove first password and append new one in array
              [..._.drop(userData.prevPasswords), encryptedPassword] :
              [...userData.prevPasswords, encryptedPassword]
            }
          } else {
            allEncryptedPSW = [encryptedPassword];
          }
          // Update user record with passwordExpireOn,encryptedPassword,prevPasswords
          const passwordExpireOn = moment.utc().add(60, 'days').format()
          await ref.update({
            passwordExpireOn,
            encryptedPassword,
            prevPasswords: allEncryptedPSW,
          });
        }
      }
      return null;
    } catch (error) {
      const  message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  isPasswordExpired = async ({ userId }) => {
    try {
      if (!userId) { throw new Error('userId is required'); }

      let isPasswordExpired = false;
      const userSnap = await this.db.collection('users').doc(userId).get();
      const userData = userSnap.data();
      if (userData && userData.passwordExpireOn) {
        const todaysDate = moment.utc();
        if(moment(todaysDate).isAfter(userData.passwordExpireOn)){
          isPasswordExpired = true;
        }
      }
      return isPasswordExpired;
    } catch (error) {
      const  message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
  getCurrentUser = () => this.auth.currentUser;
  doOnAuthStateChanged = (callback) => this.auth.onAuthStateChanged(callback);

  // DB reference
  getDBBatch = () => this.db.batch();

  // User functions
  getUsers = () => this.db.collection('users').get();
  getUserByID = (id) => this.db.collection('users').doc(id).get();
  addUser = (userData, uid) =>
    this.db.collection('users').doc(uid).set(userData);
  uploadProfilePicture = (file, familyID) =>
    this.storage.child(`/userProfiles/${familyID}/` + file.name).put(file);
  getDownloadURLForProfileImage = (file, familyID) =>
    this.storage
      .child(`/userProfiles/${familyID}/` + file.name)
      .getDownloadURL();
  deleteProfileImageFromStorage = (storagePath) =>
    this.storage.child(storagePath).delete();
  deleteUser = (id) => this.db.collection('users').doc(id).delete();
  isUserExists = (email) =>
    this.db.collection('users').where('email', '==', email).limit(1).get();

  // School form tab functions
  uploadSchoolImage = (file, schoolId, type) =>
    this.storage
      .child(`/schools/${setSchoolFileName(file, schoolId, type)}`)
      .put(file);
  getDownloadURLForSchoolFile = (file, schoolId, type) =>
    this.storage
      .child(`/schools/${setSchoolFileName(file, schoolId, type)}`)
      .getDownloadURL();
  deleteSchoolFileFromStorage = (storagePath) =>
    this.storage.child(storagePath).delete();
  addSchoolFormDetails = (schoolId, formData = null) =>
    this.db
      .collection('schools')
      .doc(schoolId)
      .get()
      .then((schoolData) => {
        const updatedData = {
          ...schoolData.data(),
          formData,
          formUrl: formData?.urlPath || null,
          isPublished: !!formData?.isPublished
        };
        updatedData.categories = updatedData?.categories.map((category) => {
          const cData = formData?.category?.find((c) => c.id === category.id);
          const updatedCategory = {
            ...category,
            isDisplay: !!cData?.isDisplay
          };
          return updatedCategory;
        });
        return schoolData.ref.set(updatedData);
      });
  isUrlAvailable = (url, schoolId) => {
    return this.db
      .collection('schools')
      .where('formUrl', '==', url)
      .get()
      .then((school) => {
        if (!school.docs.length) return true;
        else if (
          school?.docs.filter((data) => data.data().id === schoolId).length
        )
          return true;
        else return false;
      })
      .catch(function (error) {
        console.log('Error: ', error);
        return error;
      });
  };

  // Families functions
  getPaidFamilies = () =>
    this.db
      .collection('families')
      .where('planType', 'in', [
        'Paid 1-Child',
        'Paid 2-Child',
        'Paid 3-Child',
        'Paid 4-Child'
      ])
      .get();
  getFamilies = () =>
    this.db.collection('families').orderBy('familySurname').get();
  getFamilyByID = (id) => this.db.collection('families').doc(id).get();
  setFamily = async (familyData, accountStatus = freeFamilyChildStatus.NEW) => {
    let formatedFamily = { ...familyData };
    if (familyData.planType === 'Free') {
      formatedFamily = formatFreeFamily(familyData, accountStatus);
    }else {
      formatedFamily = familyData;
    }
    formatedFamily = { ...formatedFamily, primaryEmail: _.toLower(formatedFamily.primaryEmail) };
    let promise = new Promise((resolve, reject) => {
      this.db
        .collection('families')
        .add(formatedFamily)
        .then(async(docRef) => {
          docRef.update({ id: docRef.id });
          if (accountStatus === freeFamilyChildStatus.INVITED && formatedFamily.childs.length > 0) {
            const schoolData = await this.getSchoolByID(formatedFamily.childs[0].school);
            await emailToApprovedFamily(formatedFamily, schoolData.data().schoolName);
          }
          // this.db.collection('entries').add({
          //   entries: [],
          //   familyId: docRef.id
          // });
          resolve(docRef);
        });
    });
    return promise;
  };
  updateFamily = (familyData, id, accountStatus = freeFamilyChildStatus.ACTIVE) => {
    let formatedFamily = { ...familyData };
    if (familyData.planType === 'Free') {
      formatedFamily = formatFreeFamily(familyData, accountStatus);
    }
    formatedFamily = { ...formatedFamily, primaryEmail: _.toLower(formatedFamily.primaryEmail) };
    return this.db.collection('families').doc(id).set(formatedFamily);
  }
  //only child schools
  getFamilyBySchoolId = (id) =>
    this.db
      .collection('families')
      .where('childsSchools', 'array-contains', id)
      .get();
  // all family associated with this id
  getFamilyBySchoolIdCalendarHost = (id) =>
    this.db
      .collection('families')
      .orderBy('familySurname')
      .where('schoolIds', 'array-contains', id)
      .get();
  getFreeFamiliesByStatus = (status = 'Active') => {
    let statusToFilter = [freeFamilyChildStatus.ACTIVE];
    if (status === 'Pending')
      statusToFilter = [
        freeFamilyChildStatus.INVITED,
        freeFamilyChildStatus.REMIND1,
        freeFamilyChildStatus.REMIND2,
        freeFamilyChildStatus.OPEN,
        freeFamilyChildStatus.OPEN_REMIND
      ];
    if (status === 'New') statusToFilter = [freeFamilyChildStatus.NEW];
    return this.db
      .collection('families')
      .where('planType', '==', 'Free')
      .where('accountStatus', 'array-contains-any', statusToFilter)
      .get();
  };
  approveFamiliesById = (familyIds, status = 'Invited') => {
    const arrayOfArray = new Array(Math.ceil(familyIds.length / 10))
      .fill()
      .map(() => familyIds.splice(0, 10));
    const promises = Promise.all(
      arrayOfArray.map(async (fIds) => {
        const querySnapshot = await this.db.collection('families').where('id', 'in', fIds).get();
        const batch = app.firestore().batch();
        await Promise.all(querySnapshot.docs.map(async (doc) => {
          const childs = doc.data().childs.map((child) => {
            let updatedChild = { ...child };
            if (child.status === 'New') {
              updatedChild = {
                ...child,
                status,
                statusUpdatedAt: moment.utc().format()
              }
            }
            return updatedChild;
          });
          try {
            const schoolData = await this.getSchoolByID(doc.data().schoolIds[0]);
            await emailToApprovedFamily(doc.data(), schoolData.data().schoolName);
            const accountStatus = childs.map(child => child.status);
            batch.update(doc.ref, { childs, accountStatus });
          } catch (err) {
            console.log('Error:', err.message);
            throw new Error(err.message);
          }
          return null;
        }));
        return batch.commit();
      })
    )
    return promises;
  }
  deleteNonActiveFamiliesById = (familyIds) => {
    const arrayOfArray = new Array(Math.ceil(familyIds.length / 10))
      .fill()
      .map(() => familyIds.splice(0, 10));
    const promises = Promise.all(
      arrayOfArray.map((fIds) => {
        return this.db
          .collection('families')
          .where('id', 'in', fIds)
          .get()
          .then(function (querySnapshot) {
            const nonActiveFamilies = querySnapshot.docs.filter(family => !family.data().accountStatus.includes('Active'));
            var batch = app.firestore().batch();
            nonActiveFamilies.forEach(family => {
              batch.delete(family.ref);
            });
            return batch.commit();
          })
          .catch(function (error) {
            console.log('Error: ', error);
            return error;
          });
      })
    );
    return promises;
  }
  deleteFamily = (id) => this.db.collection('families').doc(id).delete();

  // Files fuctions
  getFiles = (familyID) =>
    this.db
      .collection('files')
      .where('familyId', '==', familyID)
      .orderBy('uploadDate', 'desc')
      .get();
  addFile = (fileData) => this.db.collection('files').add(fileData);
  deleteFileDocument = (id) => this.db.collection('files').doc(id).delete();

  // Storage functions
  uploadFile = (file, familyID, fileName) =>
    this.storage.child(`/userFiles/${familyID}/` + fileName).put(file);
  getDownloadURL = (file, familyID, fileName) =>
    this.storage.child(`/userFiles/${familyID}/` + fileName).getDownloadURL();
  deleteFileFromStorage = (fileName, familyID) =>
    this.storage.child(`/userFiles/${familyID}/` + fileName).delete();

  // Schools functions
  getFreeSchools = () =>
    this.db
      .collection('schools')
      .where('freeCalendars', 'in', [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13'
      ])
      .orderBy('schoolName')
      .get();
  getSchools = () => this.db.collection('schools').orderBy('schoolName').get();
  getSchoolByID = (id) => this.db.collection('schools').doc(id).get();
  addSchool = (schoolData) => {
    let promise = new Promise((resolve, reject) => {
      this.db
        .collection('schools')
        // adding default 4 categories to all new schools
        .add({
          ...schoolData,
          categories: [
            { id: generateFileHash(20), name: 'Class' },
            { id: generateFileHash(20), name: 'Co-Curricular' },
            { id: generateFileHash(20), name: 'Staff' },
            { id: generateFileHash(20), name: 'Subjects' }
          ]
        })
        .then((docRef) => {
          docRef.update({ id: docRef.id });
          this.db.collection('schoolEntries').add({
            entries: [],
            schoolId: docRef.id
          });
          resolve(docRef);
        });
    });
    return promise;
  };
  updateSchool = (schoolData, id) =>
    this.db.collection('schools').doc(id).set(schoolData);
  deleteSchool = (id) => this.db.collection('schools').doc(id).delete();

  // agents functions
  getAgents = () =>
    this.db
      .collection('users')
      .where('role', 'in', ['Agent', 'Manager'])
      .orderBy('firstName')
      .get();
  addAgent = (agentData) => this.db.collection('users').add(agentData);
  updateAgent = (agentData, id) =>
    this.db.collection('users').doc(id).update(agentData);
  deleteAgent = (id) => this.db.collection('users').doc(id).delete();
  uploadAgentsProfilePicture = (file) =>
    this.storage.child(`/userProfiles/agents/` + file.name).put(file);
  getDownloadURLForAgentsProfileImage = (file) =>
    this.storage.child(`/userProfiles/agents/` + file.name).getDownloadURL();
  deleteAgentsProfileImageFromStorage = (storagePath) =>
    this.storage.child(storagePath).delete();
  getGroupBySchoolId = (id) =>
    this.db.collection('groups').where('school', '==', id).get();

  // groups functions
  getGroup = () => this.db.collection('groups').get();
  addGroup = (groupData) => this.db.collection('groups').add(groupData);
  updateGroup = (groupData, id) =>
    this.db.collection('groups').doc(id).set(groupData);
  updateGroupsByFamilyId = async ({ familyId, groupIds }) => {
    const promises = Promise.all(
      groupIds.map((group) => {
        return this.db
          .collection('groups')
          .doc(group.id)
          .update({
            groupMembers: group.isAdd
              ? app.firestore.FieldValue.arrayUnion(familyId)
              : app.firestore.FieldValue.arrayRemove(familyId)
          });
      })
    );
    return promises;
  };
  updateFamilyByGroupId = async ({ groupId, families }) => {
    const allFamilies = families.filter((family) => !!family.familyId);
    const arrayOfArray = new Array(Math.ceil(allFamilies.length / 10))
      .fill()
      .map(() => allFamilies.splice(0, 10));
    const promises = Promise.all(
      arrayOfArray.map((familyArray) => {
        if (_.compact(_.map(familyArray, 'familyId')).length) {
          return this.db
            .collection('families')
            .where('id', 'in', _.compact(_.map(familyArray, 'familyId')))
            .get()
            .then(function (querySnapshot) {
              var batch = app.firestore().batch();
              querySnapshot.docs.forEach(function (doc) {
                const childs = doc.data().childs.map((child) => {
                  const updateData = _.find(
                    familyArray,
                    (family) => family.childId == child.id
                  );
                  if (updateData) {
                    if (updateData.isAdd) {
                      child.groups = !child.groups.length
                        ? [groupId]
                        : _.uniq([...child.groups, groupId]);
                    } else {
                      if (child.groups != '' && child.groups.length) {
                        child.groups = _.filter(
                          child.groups,
                          (group) => group != groupId
                        );
                      }
                      if (child.group === groupId) child.group = '';
                    }
                  }
                  return child;
                });
                batch.update(doc.ref, { childs });
              });
              return batch.commit();
            })
            .catch(function (error) {
              console.log('Error: ', error);
              return error;
            });
        }
        return false;
      })
    );
    return promises;
  };

  deleteGroup = (id) => this.db.collection('groups').doc(id).delete();

  // Category functions
  getGroupsByCategory = (schoolId, categoryIds) => {
    if (categoryIds.length) {
      return this.db
        .collection('groups')
        .where('school', '==', schoolId)
        .where('category', 'in', categoryIds)
        .get();
    } else {
      return this.db.collection('groups').where('school', '==', schoolId).get();
    }
  };

  getCategoryNameForGroups = (groups) =>
    this.db
      .collection('schools')
      .where(
        'id',
        'in',
        groups.map((group) => group.school)
      )
      .get()
      .then((querySnapshot) => {
        const updatedGroups = groups.map((group) => {
          const school = querySnapshot.docs.find(
            (school) => school.data().id === group.school
          );
          const category =
            school && school.data().categories
              ? school
                  .data()
                  .categories.find((category) => category.id === group.category)
              : null;
          const updatedGroup = {
            ...group,
            categoryName: category ? category.name : null
          };
          return updatedGroup;
        });
        return updatedGroups;
      })
      .catch(function (error) {
        console.log('Error: ', error);
      });

  // Entries
  updateSpecificEntry = (data) => {
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('entries').doc(data.docId);
      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data().entries.map((entry) => {
            if (entry.id === data.entry.id) {
              entry = data.entry;
            }
            return entry;
          });

          let batch = app.firestore().batch();

          batch.update(ref, {
            entries: querydata
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };

  updateSpecificEntrySchool = (data) => {
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('schoolEntries').doc(data.docId);
      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data().entries.map((entry) => {
            if (entry.id === data.entry.id) {
              entry = data.entry;
            }
            return entry;
          });

          let batch = app.firestore().batch();

          batch.update(ref, {
            entries: querydata
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };

  deleteSpecificEntries = (data) => {
    const parentID = data.parentID;
    //console.log('existingEntry', data)
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('entries').doc(data.docId);
      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data();
          const existingEntry = querydata.entries.filter(
            (entry) => entry.primaryID === parentID || entry.id === parentID
          );
          //console.log('existingEntry', existingEntry)
          let batch = app.firestore().batch();
          existingEntry.forEach((entry) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayRemove(entry)
            });
            // .then(function () {
            //   resolve();
            // }).catch(function (error) {
            //   console.error("Error deleting document: ", error);
            // });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };
  deleteSpecificSchoolEntries = (data) => {
    const parentID = data.parentID;
    //console.log('existingEntry', data)
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('schoolEntries').doc(data.docId);

      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data();
          const existingEntry = querydata.entries.filter(
            (entry) => entry.primaryID === parentID || entry.id === parentID
          );
          //console.log('existingEntry', existingEntry)
          let batch = app.firestore().batch();
          existingEntry.forEach((entry, index) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayRemove(entry)
            });
            // .then(function () {

            //   if (index === (existingEntry.length - 1)) {
            //     console.log('existingEntry', index, existingEntry);

            //   }
            // }).catch(function (error) {
            //   console.error("Error deleting document: ", error);
            // });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };
  addNewEntries = (data) => {
    const parentID = data.parentID;
    const isUpdate = data.isUpdate;
    let promise = new Promise((resolve, reject) => {
      if (data.docId === 'newId') {
        // console.log('newId', data);
        this.db
          .collection('entries')
          .add({
            entries: data.entries,
            familyId: data.familyId
          })
          .then(function () {
            resolve();
          })
          .catch((error) => reject(error));
      } else {
        const ref = this.db.collection('entries').doc(data.docId);
        if (isUpdate) {
          //console.log('isUpdate', parentID);
          ref
            .get()
            .then(function (querySnapshot) {
              //var batch = app.firestore().batch();
              const querydata = querySnapshot.data();
              const existingEntry = querydata.entries.filter(
                (entry) => entry.primaryID === parentID || entry.id === parentID
              );
              //console.log('existingEntry', existingEntry);

              let batch = app.firestore().batch();
              if (existingEntry && existingEntry.length) {
                existingEntry.forEach((entry) => {
                  batch.update(ref, {
                    entries: app.firestore.FieldValue.arrayRemove(entry)
                  });
                });
              }
              data.entries.forEach((entry) => {
                batch.update(ref, {
                  entries: app.firestore.FieldValue.arrayUnion(entry)
                });
              });
              batch
                .commit()
                .then(() => {
                  console.log('Success!');
                  resolve();
                })
                .catch((err) => console.error('Firebase commit Failed!', err));
            })
            .catch(function (error) {
              console.log('Transaction failed: ', error);
            });
        } else {
          let batch = app.firestore().batch();
          data.entries.forEach((entry) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayUnion(entry)
            });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        }
      }
    });
    return promise;
  };
  addEntries = (entries) => this.db.collection('entries').add(entries);
  getEntries = (familyID) =>
    this.db.collection('entries').where('familyId', '==', familyID).get();
  getEntriesByGroupEntryID = (groupEntryID) =>
    this.db
      .collection('entries')
      .get()
      .then((querySnapshot) => {
        let groupEntries = [];
        querySnapshot.forEach((doc) => {
          const entryData = doc.data();
          const entries = (entryData.entries || []).filter(
            (entry) => entry.groupEntryID === groupEntryID
          );
          if (entries.length) {
            groupEntries = [
              ...groupEntries,
              { docId: doc.id, ...entryData, entries }
            ];
          }
        });
        return groupEntries;
      });
  updateEntries = (entryData, id) =>
    this.db.collection('entries').doc(id).set(entryData);
  deleteEntries = (familyID) =>
    this.db
      .collection('entries')
      .where('familyId', '==', familyID)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });

  // School Entries
  addNewSchoolEntries = (data) => {
    const parentID = data.parentID;
    const isUpdate = data.isUpdate;
    let promise = new Promise((resolve, reject) => {
      if (data.docId === 'newId') {
        //console.log('addNewSchoolEntries', data);
        this.db
          .collection('schoolEntries')
          .add({
            entries: data.entries,
            schoolId: data.schoolId
          })
          .then(function () {
            resolve();
          })
          .catch((error) => reject(error));
      } else {
        const ref = this.db.collection('schoolEntries').doc(data.docId);
        if (isUpdate) {
          //console.log('isUpdate', parentID);
          ref
            .get()
            .then(function (querySnapshot) {
              //var batch = app.firestore().batch();
              const querydata = querySnapshot.data();
              const existingEntry = querydata.entries.filter(
                (entry) => entry.primaryID === parentID || entry.id === parentID
              );
              //console.log('existingSchoolEntry', existingEntry);
              let batch = app.firestore().batch();

              existingEntry.forEach((entry) => {
                batch.update(ref, {
                  entries: app.firestore.FieldValue.arrayRemove(entry)
                });
              });
              data.entries.forEach((entry) => {
                batch.update(ref, {
                  entries: app.firestore.FieldValue.arrayUnion(entry)
                });
              });
              batch
                .commit()
                .then(() => {
                  console.log('Success!');
                  resolve();
                })
                .catch((err) => console.error('Failed!', err));
            })
            .catch(function (error) {
              console.log('Transaction failed: ', error);
            });
        } else {
          let batch = app.firestore().batch();
          data.entries.forEach((entry) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayUnion(entry)
            });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        }
      }
    });
    return promise;
  };
  addSchoolEntries = (entries) =>
    this.db.collection('schoolEntries').add(entries);
  getSchoolEntries = (schoolId) =>
    this.db.collection('schoolEntries').where('schoolId', '==', schoolId).get();
  getSchoolsArrayEntries = (schoolArray) =>
    this.db
      .collection('schoolEntries')
      .where('schoolId', 'in', schoolArray)
      .get();
  getSchoolEntriesByGroupEntryID = (groupEntryID) =>
    this.db
      .collection('schoolEntries')
      .get()
      .then((querySnapshot) => {
        let groupEntries = [];
        querySnapshot.forEach((doc) => {
          const entryData = doc.data();
          const entries = (entryData.entries || []).filter(
            (entry) => entry.groupEntryID === groupEntryID
          );
          if (entries.length) {
            groupEntries = [
              ...groupEntries,
              { docId: doc.id, ...entryData, entries }
            ];
          }
        });
        return groupEntries;
      });
  updateSchoolEntries = (entryData, id) =>
    this.db.collection('schoolEntries').doc(id).set(entryData);

  deleteSchoolEntries = (id) =>
    this.db
      .collection('schoolEntries')
      .where('schoolId', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  // Group entries
  addNewGroupEntries = (data) => {
    let promise = new Promise((resolve, reject) => {
      if (data.docId === 'newId') {
        this.db
          .collection('groupEntries')
          .add({
            entries: data.entries,
            groupEntryID: data.groupEntryID,
            groupType: data.groupType
          })
          .then(function () {
            resolve();
          });
      }
    });
    return promise;
  };
  getGroupEntries = (groupEntryID) =>
    this.db
      .collection('groupEntries')
      .where('groupEntryID', '==', groupEntryID)
      .get()
      .then((querySnapshot) => {
        let groupEntries = [];
        let docId = '';
        querySnapshot.forEach((doc) => {
          const entryData = doc.data();
          docId = doc.id;
          groupEntries.push(entryData);
        });
        if (groupEntries && groupEntries.length) {
          groupEntries[0].docId = docId;
          return groupEntries[0];
        }
        return groupEntries;
      });
  updateGroupEntries = (entryData, id) =>
    this.db.collection('groupEntries').doc(id).set(entryData);
  deleteGroupEntries = (id) =>
    this.db.collection('groupEntries').doc(id).delete();
  deleteFamiliesEntries = (id) =>
    this.db.collection('entries').doc(id).delete();
  updateGroupMemberAndYearsInEntry = (data, docId) =>
    this.db.collection('schooldataentries').doc(docId).set(data);
  updateGroupEntriesByFamilyId = ({ familyId, groupIds, familyGroups }) => {
    this.db
      .collection('schooldataentries')
      .where('groups', 'array-contains-any', _.map(groupIds, 'id'))
      .get()
      .then(function (querySnapshot) {
        const addIds = _.map(
          _.filter(groupIds, (id) => id.isAdd),
          'id'
        );
        const removeIds = _.map(
          _.filter(groupIds, (id) => !id.isAdd),
          'id'
        );
        var batch = app.firestore().batch();
        addIds.forEach((groupId) => {
          querySnapshot.docs
            .filter((doc) => doc.data().groups.includes(groupId))
            .forEach((doc) => {
              batch.update(doc.ref, {
                groupMembers: _.uniq([...doc.data().groupMembers, familyId])
              });
            });
        });
        removeIds.forEach((groupId) => {
          querySnapshot.docs
            .filter((doc) => doc.data().groups.includes(groupId))
            .forEach((doc) => {
              if (
                familyGroups.filter((group) =>
                  doc.data().groups.includes(group)
                ).length == 0
              ) {
                batch.update(doc.ref, {
                  groupMembers: _.filter(
                    doc.data().groupMembers,
                    (member) => member != familyId
                  )
                });
              }
            });
        });
        return batch.commit();
      });
  };

  updateGroupEntriesByGroupId = ({ groupId, families }) => {
    this.db
      .collection('schooldataentries')
      .where('groups', 'array-contains', groupId)
      .get()
      .then(function (querySnapshot) {
        const addIds = _.map(
          _.filter(families, (family) => family.isAdd),
          'childId'
        );
        var batch = app.firestore().batch();
        querySnapshot.docs.forEach(function (doc) {
          const removeIds = _.map(
            _.filter(
              families,
              (family) =>
                !family.isAdd &&
                _.filter(family.familyGroups, (group) =>
                  doc.data().groups.includes(group)
                ).length == 0
            ),
            'childId'
          );
          let updated = _.filter(
            doc.data().groupMembers,
            (member) => !_.includes(removeIds, member)
          );
          updated = _.uniq([...updated, ...addIds]);
          batch.update(doc.ref, { groupMembers: updated });
        });
        return batch.commit();
      });
  };

  // Data entries for paid families
  getDataEntries = (familyID) =>
    this.db.collection('dataentries').where('familyId', '==', familyID).get();
  bulkDataEntries = (bulkID) =>
    this.db.collection('dataentries').where('bulkID', '==', bulkID).get();
  addDataEntries = (entry) => this.db.collection('dataentries').add(entry);
  deleteDataEntries = (docId, id) => {
    // Delete relational entries
    this.db
      .collection('dataentries')
      .where('bulkID', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
    // Delete Primaty Entry
    return this.db.collection('dataentries').doc(docId).delete();
  };
  // below function was created as a temporary, used for data migration
  deleteFamillyDataEntries = (id) => {
    this.db
      .collection('dataentries')
      .where('familyId', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  };
  // repeated primary entry update function
  deleteRepeatedEntry = (entry) =>
    this.db.collection('dataentries').doc(entry.docId).set(entry);

  // Data entries for schools
  getSchoolDataEntries = (schoolID) =>
    this.db
      .collection('schooldataentries')
      .where('schoolId', '==', schoolID)
      .get();
  schoolBulkDataEntries = (bulkID) =>
    this.db.collection('schooldataentries').where('bulkID', '==', bulkID).get();
  addSchoolDataEntries = (entry) =>
    this.db.collection('schooldataentries').add(entry);
  deleteSchoolDataEntries = (docId, id) => {
    // Delete relational entries
    this.db
      .collection('schooldataentries')
      .where('bulkID', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
    // Delete Primaty Entry
    return this.db.collection('schooldataentries').doc(docId).delete();
  };
  // repeated primary entry update function
  deleteSchoolRepeatedEntry = (entry) =>
    this.db.collection('schooldataentries').doc(entry.docId).set(entry);

  getMultipleSchoolsDataEntries = (schoolArray) =>
    this.db
      .collection('schooldataentries')
      .where('schoolId', 'in', schoolArray)
      .get();

  // delete for a group entries after edit as a whole group
  deleteGroupDataEntries = (groupId) => {
    // Delete relational entries
    this.db
      .collection('dataentries')
      .where('groupEntryID', '==', groupId)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  };
}

export default Firebase;
