import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate 
} from "react-router-dom";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "./utils/theme";
import { routes, colors } from "./utils/constant";

import MobileCreatePasswordView from "./Views/MobileCreatePassword/index";
import PageNotFoundView from "./Views/404/PageNotFound";
import MobilePasswordUpdatedView from "./Views/MobilePasswordUpdatedView/index";
import UnsubsribeView from "./Views/UnsubsribeView";
import ResubscribeView from './Views/ResubscribeView';

const useStyles = makeStyles({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    /* Handle on hover */
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: colors.primary,
      "&:hover": {
        background: "#81b715",
      },
    },
    ".MuiButton-root": {
      textTransform: "none",
    },
    ".MuiButton-containedSizeLarge": {
      fontSize: "16px",
      lineHeight: 1.5,
      height: 50,
    },
    ".MuiInputBase-input": {
      height: 23,
    },
    ".MuiTypography-gutterBottom": {
      marginBottom: 16,
    },
    ".MuiSnackbarContent-root": {
      padding: "2px 24px",
      backgroundColor: colors.tertiaryConfirm,
      borderRadius: "34.5px",
      minWidth: "50px",
      width: "auto",
    },
    ".MuiInputLabel-outlined": {
      lineHeight: "22px",
    },
    ".MuiBackdrop-root": {
      backgroundColor: `rgba(76, 91, 104, 0.6) !important`,
    },
  },
});

const RoutesComponent = () => {
  useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Routes>
          <Route exact path={"/"}  element={<PageNotFoundView/>} />
          <Route
            exact
            path={routes.resetPassword}
            element={<MobileCreatePasswordView />}
          />

          <Route
            exact
            path={routes.pageNotFound}
            element={<PageNotFoundView/>}
          />
           <Route
            exact
            path={routes.appPasswordUpdated}
            element={<MobilePasswordUpdatedView />}
          />
          <Route
            exact
            path={routes.unSubscribeEmail}
            element={<UnsubsribeView />}
          />
          <Route
            exact
            path={routes.reSubscribe}
            element={<ResubscribeView />}
          />
          {/* <Navigate to={routes.pageNotFound} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};
export default RoutesComponent;
