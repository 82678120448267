import _ from 'lodash';

// Initial State
const initialState = {
  isLoading: false,
  message: [],
  hasPreserveEntryData: null,
  hasFamilyOrSchoolEntryData: null
};

// Reducers (Modifies The State And Returns A New State)
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case 'LOADER_START': {
      return {
        ...state,
        isLoading: true
      };
    }
    case 'LOADER_STOP': {
      return {
        ...state,
        isLoading: false
      };
    }
    case 'ADD_MESSAGE': {
      return {
        ...state,
        message: [...state.message, action.message]
      };
    }
    case 'REMOVE_MESSAGE': {
      return {
        ...state,
        message: []
      };
    }
    case 'SET_PRESERVE_ENTRY_DATA': {
      return {
        ...state,
        hasPreserveEntryData: _.cloneDeep(action.hasPreserveEntryData)
      };
    }
    case 'SET_FAMILY_OR_SCHOOL_PRESERVE_ENTRY_DATA': {
      return {
        ...state,
        hasFamilyOrSchoolEntryData: _.cloneDeep(action.hasFamilyOrSchoolEntryData)
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default appReducer;
