import './App.css';
import Routes from './Route';

function App() {
  return (
    <div className="App">
     <Routes/>
    </div>
  );
}

export default App;
