import { groupSchema } from "../../utils/schemas";
// Initial State
const initialState = {
  currentGroup: groupSchema,
};

// Reducers (Modifies The State And Returns A New State)
const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    // Group Set
    case "SET_GROUP": {
      return {
        ...state,
        currentGroup: { ...state.currentGroup, ...action.group },
      };
    }
    case "RESET_GROUP": {
      return {
        ...state,
        currentGroup: groupSchema,
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default groupReducer;