// Loader Start
export const loaderStart = () => ({
  type: 'LOADER_START'
});

// Loader Stop
export const loaderStop = () => ({
  type: 'LOADER_STOP'
});

// Add Message
export const addMessage = (error) => ({
  type: 'ADD_MESSAGE',
  message: error
});

// Add Message
export const removeMessage = () => ({
  type: 'REMOVE_MESSAGE'
});

// Add Message
export const setPreserveEntryData = (hasPreserveEntryData) => ({
  type: 'SET_PRESERVE_ENTRY_DATA',
  hasPreserveEntryData
});

export const setFamilyOrSchoolPreserveEntryData = (hasFamilyOrSchoolEntryData) => ({
  type: 'SET_FAMILY_OR_SCHOOL_PRESERVE_ENTRY_DATA',
  hasFamilyOrSchoolEntryData
});
