import React from "react";
import {
  Card,
  CardContent,
  Container,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Logo from "../assets/Logo-NoSubTitle-InnerAdmin@3x.png";
import { colors } from "../utils/constant";
import MessageSnackbar from '../component/Message';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    backgroundColor: "#F4F6F8",
    display: "flex",
    height: "100%",
    minHeight: "100vh",
    flexDirection: "column",
    paddingBottom: 80,
    paddingTop: 80,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.primary,
  },
  card: {
    overflow: "visible",
    display: "flex",
    position: "relative",
    maxWidth: 621,
    margin: "auto",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(7, 7, 7, 7),
  },
  logo: {
    alignSelf: "center",
    marginBottom: "54px",
  },
}));

function FullPage({ children, cardContentProps }) {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.app.isLoading);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoading || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MessageSnackbar />
      <div className={classes.logo}>
        <img src={Logo} alt="err" style={{ width: "160px", height: "80px" }} />
      </div>
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent
            classes={{ root: classes.content }}
            {...cardContentProps}
          >
            {children}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default FullPage;
