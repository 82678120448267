import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  Box,
  Typography,
  Button,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import FullPage from "../../layout/FullPage";
import { routes } from "../../utils/constant";
import { withFirebase, FIREBASE_API_URL } from "../../utils/firebase";
import { addMessage } from "../../redux/actions/appActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  constWrapper: {
    marginLeft: 55,
    marginRight: 55,
  },
  imageWrapper: {
    marginRight: "20px",
  },
  typo1: {
    fontSize: "16px",
    marginTop: 24,
  },
  typo2: {
    fontSize: "32px",
    marginTop: 24,
  },
  typo3:{
    fontSize: 16,
    marginBottom: '262px',
    width: 259,
    textAlign:'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 24
  }
}));

function ResubsribeView({ firebase }) {
  const classes = useStyles();
  const location = useLocation();
  console.log(location)

  return (
    <FullPage>
      <div className={classes.constWrapper}>
        <Box mt={2}>
          <Typography className={classes.typo1}>
            {location?.state?.data?.email}
          </Typography>

          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom={true}
            className={classes.typo2}
          >
            You’re Subscribed!
          </Typography>

          <Typography className={classes.typo3}>
            You will now receive emails from the {location?.state?.data?.listName} mailing list
          </Typography>
        </Box>
      </div>
    </FullPage>
  );
}

export default withFirebase(ResubsribeView);
