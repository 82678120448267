const schoolSchema = {
  schoolName: '',
  country: '',
  timezone: '',
  address1: '',
  address2: '',
  state: '',
  postCode: '',
  websiteUrl: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  freeCalendars: [],
  createdDate: '',
  notes: ''
};

const familySchema = {
  planType: '',
  familySurname: '',
  country: '',
  timezone: '',
  userName: '',
  password: '',
  childs: [],
  childsSchools: [],
  parents: [],
  freeHost: false,
  myWhanauEmail: '',
  schools: '',
  planStatus: '',
  createdDate: '',
  id: ''
};

const parentSchema = {
  isPrimary: '',
  firstName: '',
  lastName: '',
  initials: '',
  emailAddress: '',
  profileImage: '',
  id: ''
};

const childSchema = {
  userId: '',
  firstName: '',
  lastName: '',
  initials: '',
  gender: '',
  userName: '',
  password: '',
  school: '',
  year: '',
  schoolPortal: '',
  schoolPortalLogin: '',
  schoolPortalPassword: '',
  group: '',
  groups: [],
  notes: '',
  color: ''
};

const agentSchema = {
  firstName: '',
  lastName: '',
  initials: '',
  profileImage: '',
  email: '',
  password: '',
  role: ''
};

const groupSchema = {
  groupName: '',
  school: '',
  groupMembers: [],
  years: []
};

export {
  schoolSchema,
  familySchema,
  parentSchema,
  childSchema,
  agentSchema,
  groupSchema
};
