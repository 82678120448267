import { agentSchema } from '../../utils/schemas';
// Initial State
const initialState = {
  currentAgent: agentSchema
};

// Reducers (Modifies The State And Returns A New State)
const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    // Agent Set
    case 'SET_AGENT': {
      return {
        ...state,
        currentAgent: { ...state.currentAgent, ...action.agent }
      };
    }
    case 'RESET_AGENT': {
      return {
        ...state,
        currentAgent: agentSchema
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default agentReducer;
